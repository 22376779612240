import React, {useState, useRef} from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from 'gatsby-image';

import DesktopNav from './desktop_nav';
import "../styles/site.scss";
import MobileNavButton from "./mobile_nav_button";
import MobileNavMenu from './mobile_nav_menu';
import PageLogo from './page_logo';
import BackgroundLogo from "./background_logo";

const Layout = ({ children, showPageLogo: showPageLogo=true}) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  }

  const mainDiv = useRef(null);

  const onMainDivLoad = () => {
    mainDiv.current.style.height = `${window.innerHeight * .75}px`;
  }

  return (
    <>
      <meta name="viewport" content="initial-scale=1.0, user-scalable=no" />
      <DesktopNav />
      <div ref={mainDiv} onLoad={onMainDivLoad} >
        {showPageLogo && <PageLogo />}
        <main>{children}</main>
        <footer>
          <MobileNavButton onClick={toggleMobileNav} />
          © {new Date().getFullYear()}, <Link to='/' activeClassName='activeHomeLink'>Spring City Coffee, LLC</Link>
        </footer>
      </div>
      <MobileNavMenu isVisible={isMobileMenuVisible} setIsVisible={setIsMobileMenuVisible} />
      {showPageLogo && <BackgroundLogo className='mobileHeaderBackground' />}
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
