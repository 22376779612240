import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

const BackgroundLogo = ({ className }) => {
    const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "desktop_background_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "mobile_background_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `);

    const sources = [
        {
            ...data.desktop.childImageSharp.fluid,
            media: `(min-width: 650)`,
        },
    ]

    return (
      <>
         <Img fluid={sources} className={className} />
      </>
    );
  };

  export default BackgroundLogo;