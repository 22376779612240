import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

const PageLogo = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "page_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `);

    const sources = [
        data.file.childImageSharp.fluid,
        {
            ...data.file.childImageSharp.fluid,
            media: `(min-width: 768)`,
        },
    ]

    return (
      <>
         <Img fluid={sources} className='pageLogo leftLogo' key='leftLogo' />
         <Img fluid={sources} className='pageLogo rightLogo' key='rightLogo' />
      </>
    );
  };

  export default PageLogo;